@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* reset code */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  background-color: #101518;
}

a {
  text-decoration: none;
  color: #fff;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: 100%;
}

/* end reset code */
